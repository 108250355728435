import * as React from 'react';
import { Link } from 'gatsby';

import styles from './index.module.css';

import {
  AboutWrapper,
} from './style';



const User = props => (
<div>
  <Link to={props.url}>
    <div className={styles.user}>
        <img src={props.avatar} className={styles.avatar} alt="" />
      <div className={styles.description}>
        <h3 className={styles.username}><span className={styles.themename}>{props.themename}</span>{props.username}</h3>
        <p className={styles.excerpt}>{props.excerpt}</p>
      </div>
    </div>
  </Link>
</div>
)




const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};

 
const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
    <AboutWrapper>


      <User
        url="/sdgs"
        themename="SDGs"
        username="のテーマから選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?sdgs"
        excerpt="持続可能な開発目標（SDGs）とは，2015年9月の国連サミットで採択された「持続可能な開発のための2030アジェンダ」にて記載された2030年までに持続可能でよりよい世界を目指す国際目標です。"
      />
      <User
        url="/chirisogo"
        themename="高校地理総合"
        username="の単元から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?map"
        excerpt="概念などを活用して多面的・多角的に考察したり、地理的な課題の解決に向けて構想したりする学習過程を前提に、世界の生活文化の多様性や、防災、地域や地球的課題への取組などを理解する。"
      />
      <User
        url="/chiritankyu"
        themename="高校地理探究"
        username="の単元から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?geography"
        excerpt="「地理探究」は、「地理総合」で身に付けた学習の成果を活用し、現実を踏まえての主題追究、より深い理解と課題の探究を目指す教科である。"
      />
      <User
        url="/chiritankyu"
        themename="地理的事象"
        username="の分類から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?geographic"
        excerpt="「地理探究」は、「地理総合」で身に付けた学習の成果を活用し、現実を踏まえての主題追究、より深い理解と課題の探究を目指す教科である。"
      />
      <User
        url="/sdgs"
        themename="出典"
        username="から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?book"
        excerpt="出典から選べます。"
      />
      <User
        url="/sdgs"
        themename="新しい順"
        username="から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?new"
        excerpt="新しい順から選べます。"
      />

    </AboutWrapper>

  );
};
 
export default App;



